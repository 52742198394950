import logo from './logo.svg';
import './App.css';
import Homepage from './components/Homepage';


function App() {
  return (
    <>
    <Homepage/>
    </>
  );
}

export default App;
