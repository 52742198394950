import React from 'react';
import styles from './Homepage.css';

function Homepage() {
  return (
    <div className='homepage'>
            <center><img src='/images/chain-verse.png' className='logo' /></center>
            <h1>Comming soon.</h1>
            <h3>Stay Tuned with us !!.</h3>
            <a href='https://x.com/The_chainverse'><h6>theChainVerse.com</h6></a>
    </div>
  );
}

export default Homepage;
